<template>
  <div class="nft-carousel bg-transparent relative z-30">
    <!-- ↓ データが有る場合 ↓ -------+---------+---------+---------+------ -->
    <div
      v-if="data && data.length > 0"
      class="mx-auto"
    >
      <!-- ↓ PC用カルーセル本体 ↓ ---+---------+---------+---------+------ -->
      <!-- 640px未満スマホで非表示。640px以上の大きな画面では表示。 -->
      <div  class="hidden sm:block w-full h-full" style="background-color: #000000;">
        <a-carousel
          ref="carouselPC"
          class="mx-auto bg-disabled"
          arrows
          :afterChange="onChange"
          :dots="false"
          :slidesToShow="1"
          :centerMode="true"
          :centerPadding="data.length === 1 ? '60%' : '0'"
        >
          <div v-for="item in data" :key="item.id"  :class="['text-white md:text-lg text-sm relative h-full md:cursor-pointer custom-background', canNotBuy ? 'cursor-not-allowed' : '', data.length === 1 ? 'single-slide' : '']">
            <div class="image-wrapper">
              <img
                v-if="item.pc_banner_url"
                class="w-full h-full object-contain"
                :src="item.pc_banner_url"
                @click="goToLinkPlace(item)"
              />
            </div>
          </div>
        </a-carousel>
        <div class="dot-container flex justify-center mt-2">
          <span v-for="(item, index) in data" :key="index" class="dot" :class="{ active: index === currentSlide }"></span>
        </div>
      </div>
      <!-- PC用カルーセル含むdiv終了 -->
      <!-- ↑ PC用カルーセル本体 ↑ ---+---------+---------+---------+------ -->
      <!-- ↓ スマートフォン用カルーセル本体 ↓ -+---------+---------+------ -->
      <!-- 640px未満スマホで表示。640px以上の大きな画面では非表示。 -->
      <div class="visible w-full h-full sm:hidden" style="background-color: #000000;">
        <a-carousel
          ref="carouselSmartPhone"
          class="mx-auto bg-disabled"
          arrows
          :afterChange="onChange"
          :dots="false"
          :swipe="false"
          :slidesToShow="1"
          :centerMode="true"
          :centerPadding="data.length === 1 ? '60%' : '0'"
        >
          <!-- ↓ 画像を v-for で設定 ↓ -->
          <!--@click="() => (canNotBuy ? null : $router.push(`/NFT/${item.id}`))"-->
          <div
            v-for="item in data"
            :key="item.id"
            :class="[
              'text-white md:text-lg text-sm relative h-full md:cursor-pointer',
              canNotBuy ? 'cursor-not-allowed' : '',
            ]"
          >
          <div class="image-wrapper">
              <img
                v-if="item.smartphone_banner_url"
                class="w-full h-full object-contain"
                :src="item.smartphone_banner_url"
                @touchend="goToLinkPlace(item)"
                style="pointer-events: auto;" 
              />
              <!--<div class="mt-28">{{ item.id }}: {{ localizedName(item) }} </div>-->
            </div>
          </div>
          <!-- ↑ 画像を v-for で設定 ↑ -->
        </a-carousel>
        <div class="dot-container flex justify-center mt-2">
          <span v-for="(item, index) in data" :key="index" class="dot" :class="{ active: index === currentSlide }"></span>
        </div>
      </div>
      <!-- ↑ スマートフォン用カルーセル本体 ↑ -+---------+---------+------ -->
    </div>
    <!-- ↑ データが有る場合 ↑ -------+---------+---------+---------+------ -->
    <!-- ↓ データが無い場合 ↓ -------+---------+---------+---------+------ -->
    <a-empty
      v-else
      class="empty-symbol"
    ></a-empty>
    <!-- ↑ データが無い場合 ↑ -------+---------+---------+---------+------ -->
</div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import { mapState } from 'vuex';
import { generateCalendarLink } from '@/utils/link.util';
import { SITE_URL } from '@/env';
//-------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'NFTCarousel',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      selected: 0,
      slideIntervalPC: null,
      slideIntervalSmartPhone: null,
      currentSlide: 0, 
    };
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  mounted() {
    this.autoSlide();
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    // 日本語、英語切り替えに関するもの
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ＮＦＴが販売終了なら true。
    isEnd() {
      return this.data[this.selected]?.status_code === 'FINISHED';
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ＮＦＴが売り切れなら true。
    isSoldOut() {
      return (
        Number(this.data[this.selected].presale_amount) +
          Number(this.data[this.selected].publicsale_amount) ===
        Number(this.data[this.selected].presale_sold_amount) +
          Number(this.data[this.selected].publicsale_sold_amount)
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ＮＦＴ販売終了や売り切れで購入できない場合は true。
    canNotBuy() {
      return this.isEnd || this.isSoldOut;
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    autoSlide() {
      clearInterval(this.slideIntervalPC);
      // +---------+---------+---------+---------+---------+---------+---------E
      this.slideIntervalPC = setInterval(() => {
        if (this.$refs?.carouselPC) {
          this.$refs.carouselPC.next();
        }
      }, 5000);
      // +---------+---------+---------+---------+---------+---------+---------E
      clearInterval(this.slideIntervalSmartPhone);
      // +---------+---------+---------+---------+---------+---------+---------E
      this.slideIntervalSmartPhone = setInterval(() => {
        if (this.$refs?.carouselSmartPhone) {
          this.$refs.carouselSmartPhone.next();
        }
      }, 5000);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    generateCalendarLink,
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleCalendarUrl(item) {
      const startTime = item.publicsale_start_time;
      const endTime = item.publicsale_end_time;
      const { description, name, id } = item;
      const refLink = `${SITE_URL}/NFT/${id}`;
      window.open(
        this.generateCalendarLink(name, 'Public sale', startTime, endTime, description, refLink),
        '_blank'
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleCalendarUrl(item) {
      const startTime = item.presale_start_time;
      const endTime = item.presale_end_time;
      const { description, name, id } = item;
      const refLink = `${SITE_URL}/NFT/${id}`;
      window.open(
        this.generateCalendarLink(name, 'Presale', startTime, endTime, description, refLink),
        '_blank'
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    onChange(index) {
      this.selected = index ? index : 0;
      this.currentSlide = index; // スライドが変更された時のインデックスを更新
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    handleClick() {
      clearInterval(this.slideInterval);
      setTimeout(() => {
        this.autoSlide();
      }, 7000);
    },
    //---+---------+---------+---------+---------+---------+---------+---------E
    // クリエイター名を、英語モードなら英語で、日本語モードなら日本語で返す。
    localizedName(item) {
      return this.locale === 'en' ? item.name : item.name_ja;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    // item.link の URL を開く。一番上のカルーセル画面をクリックしたときの処理。
    goToLinkPlace(item) {
      window.open(item.link, '_blank');
    },
   }, // End of methods: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  destroyed() {
    clearInterval(this.slideInterval);
  },
};
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
::v-deep.nft-carousel {
  /* height: 300px; */ /* カルーセルの高さ */
  height: 15rem; /* カルーセルの高さ */
  font-family: 'Lato', 'Poppins', sans-serif;
  .ant-carousel {
  @screen md {
     width: 45%;
    }
    @media (min-width: 1600px) {
      width: 45%;
    }
    @media (min-width: 1920px) {
    width: 35%;
    }
    @media (min-width: 2680px) {
      width: 25%;
    }
    height: 100%;
      .slick-slider {
      width: 100%;
      height: 100%;
    }
    .slick-slider {
      width: 100%;
      margin:0 auto;
      position:relative;
      overflow: visible; /* スライダーの画像が見切れても表示 */ 
      margin-bottom:30px;
      border-radius: 0px;
    }
    .slick-slider .slick-slide img {
     opacity: 0.5; /* 画像の透明度を50%に設定 */
      transition: opacity 0.5;
     transform: scale(0.55);
     display: flex !important;
     justify-content: center;
     align-items: center;
     border-radius: 10px;
    }
    .slick-slider .slick-center img{
      opacity:1;/*中央の1枚のみ明るく表示。左右は暗いまま*/
      transform: scale(0.9);
      border-radius: 10px;
    }
    
    .slick-list {
       margin: -30px;
      overflow: visible; /* スライダー全体の画像が見切れても表示 */


      background-color: #000000; 
      margin-left: -70px;/*PC画面各画像間の位置を調整*/
      margin-right: -70px;/*PC画面各画像間の位置を調整*/
    }
    .slick-prev {
      position: absolute; /* 絶対位置指定 */
      left: -12.1%; /* 左から12%の位置 */
      z-index: 10; /* 前面表示 */
      top: 50%; /* 上から50%の位置（中央に近づける） */
      transform: translateY(-50%); /* Y軸方向の中心を補正 */
    }

    .slick-next {
      position: absolute; /* 絶対位置指定 */

      right: -12.1%; /* 右から12%の位置 */
      z-index: 10; /* 前面表示 */
      top: 50%; /* 上から50%の位置（中央に近づける） */
      transform: translateY(-50%); /* Y軸方向の中心を補正 */
    }
    .slick-prev, .slick-next {
      @apply bg-white; /* 背景色を白に設定 */

      @apply p-2; /* パディングを増やして四角形を形成 */
      @apply w-10 h-10; /* 四角形の幅と高さの調整 */
      @apply flex justify-center items-center; /* 中央に矢印を配置 */
      @apply border; /* 境界線を追加 */
      @apply border-black; /* 境界線の色を黒に */
      @apply rounded-md; /* 角を丸く */
    }
    .slick-prev:before, .slick-next:before {
      @apply text-lg; /* 矢印のサイズを大きくする場合、ここを調整 */
      color: black; /* 矢印の色を黒に設定 */
    }

    .single-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 10px;
    }

    .single-slide img {
      width: auto;
      height: 100%;
      border-radius: 10px;
    }

    .image-wrapper {
      border-radius: 10px;
    margin: 0 40px; /* 左右のマージンを適用 */
    // スマートフォン向けのスタイル調整
      @media (max-width: 639px) {
         border-radius: 10px;
        margin: 0 -88px; 
        margin-top: 25px; // 画像の上方向に25pxの余白を追加
         margin-left: 10px;/*各画像間の位置を調整*/
         margin-right: 10px;/*各画像間の位置を調整*/
      }

      @media (max-width: 500px) {
        border-radius: 10px;
        margin: 0 -45px; 
        margin-top: 17px; // 画像の上方向に17pxの余白を追加
        Margin-bottom:10px;
      }
    }
  
    .custom-background {
      margin-top: 30px;
      background-color: #000000
    }
    @media (max-width: 639px) { /* 640px未満の画面で適用 */
      height: 21rem; /* カルーセルの高さを21remに設定 */
      width: 38%;
      background-color: #000000;
      .image-wrapper img {
        width: 90%; /* 画像の幅をカルーセルの90%に設定 */
        height: auto; /* 高さを自動調整 */
        margin: 0 auto; /* 画像を中央に配置 */
        object-fit: contain; /* 画像が領域に収まるように調整 */
        display: block; /* 画像をブロック要素として表示 */
        touch-action: manipulation;
        border-radius: 10px;/*バナーの画像に丸みを帯びさせる*/
      }
      .slick-list {
       margin: 0 -60px;
      overflow: visible; /* スライダー全体の画像が見切れても表示 */
      background-color: #000000; 
      }
      .slick-prev {
        left: -32%; /* 左矢印の位置を左に32%調整 */
      }
      .slick-next {
        right: -32%; /* 右矢印の位置を右に32%調整 */
      }
    }
    @media (max-width: 500px) { /* 500px未満の画面で適用 */
      height: 18rem; /* カルーセルの高さを18remに設定 */
      width: 50%;
      margin:0 auto;
      background-color: #000000;
      Margin-bottom:15px;
       .image-wrapper img {
        width: 68%; /* 画像の幅をカルーセルの70%に設定 */
        height: auto; /* 高さを自動調整 */
        margin: 0 auto; /* 画像を中央に配置 */
        object-fit: contain; /* 画像が領域に収まるように調整 */
        display: block; /* 画像をブロック要素として表示 */
        touch-action: manipulation;
        border-radius: 10px;/*バナーの画像に丸みを帯びさせる*/
       
      }
     .slick-list {
      margin: 0 -25px;
      overflow: visible; /* スライダー全体の画像が見切れても表示 */
      background-color: #000000; 
      border-radius: 10px;
      }
      .slick-prev {
        left: -25%; /* 左矢印の位置を左に25%調整 */
      }
      .slick-next {
        right: -25%; /* 右矢印の位置を右に25%調整 */
      }
    }
    @media (max-width: 399px) { /* 400px未満の画面で適用 */
      height: 15.5rem; /* カルーセルの高さを15.5remに設定 */
      .slick-track{
        display:flex;
        align-items:center;
        justify-content:center;
      }
      width: 60%;/*バナーの画像すべての大きさ変更*/
      background-color: #000000;
      Margin-bottom:20px;
      .image-wrapper img {
        width: 73%; /* 画像の幅をカルーセルの73%に設定 */
        height: auto; /* 高さを自動調整 */
        margin: 0 auto; /* 画像を中央に配置 */
        object-fit: contain; /* 画像が領域に収まるように調整 */
        display: block;  /* 画像をブロック要素として表示 */
        touch-action: manipulation;
        border-radius: 10px;/*バナーの画像に丸みを帯びさせる*/
      }
      .slick-prev {
        left: -15%; /* 左矢印の位置を左に15%調整 */
      }
      .slick-next {
        right: -15%; /* 右矢印の位置を右に15%調整 */
      }

    .slick-list {
       margin: 0 5px;
      overflow: visible; /* スライダー全体の画像が見切れても表示 */
      background-color: #000000; 
      }
      
    }
  }
  .empty-symbol {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tba-time {
    @screen 2xl {
      min-width: 245px;
    }
  }
  .property {
    min-width: 120px;
  }

  .sale-section {
    @apply xxs:px-8 pr-8;
    &-title {
      @apply text-xl font-semibold;
    }
  }
 .dot-container .dot {
  height: 10px;
  width: 10px;
  margin: 0 4px;
  margin-bottom: 20px; /* 下部に20pxの余白を追加 */
  background-color: #FFFFFF; /* 白色 */
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s; /* 色変更のアニメーションを滑らかにする */
}

.dot-container .active {
  background-color: #800080; /* 紫色 */
}
}
</style>
